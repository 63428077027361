import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Breadcrumb, Nav } from "react-bootstrap"
import Page from "../components/Page"
import FancyHeader from "../components/blocks/FancyHeader"
import NavLink from "../components/extensions/NavLink"

const SupportPage = ({ data }) => {
  const { mdx, allMdx } = data
  const { frontmatter, body } = mdx
  const { title, subTitle, path, seoTitle, seoDescription } = frontmatter

  const navItems = allMdx.nodes.map((item, key) => {
    const { title, subTitle, path } = item.frontmatter

    return (
      <NavLink key={key} to={path}>
        <span className="title">{title}</span>
        <span>{subTitle}</span>
      </NavLink>
    )
  })

  const sidebar = (
    <div className="sidebar">
      <Nav className="flex-column nav-condensed">
        {navItems}
        <NavLink to="/feedback" className="highlight">
          <span className="title">{"Email Support"}</span>
          <span>{"Still need help?"}</span>
        </NavLink>
      </Nav>
    </div>
  )

  return (
    <Page
      zeroTop
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      seoPath={path}
    >
      <FancyHeader tall inverse title={title} subTitle={subTitle} />
      <Container className="flow">
        <Row>
          <Col xs={12} sm={3}>
            {sidebar}
          </Col>
          <Col className="beautify" xs={12} sm={9}>
            <Breadcrumb>
              <Breadcrumb.Item href="/support">Support</Breadcrumb.Item>
              <Breadcrumb.Item active>{title}</Breadcrumb.Item>
            </Breadcrumb>
            <MDXRenderer>{body}</MDXRenderer>
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

export default SupportPage

export const pageQuery = graphql`
  query ($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        path
        title
        seoTitle
        seoDescription
        subTitle
      }
    }
    allMdx(
      filter: { frontmatter: { path: { regex: "/support/" } } }
      sort: { order: ASC, fields: frontmatter___priority }
    ) {
      nodes {
        frontmatter {
          path
          seoTitle
          subTitle
          title
          keywords
          priority
        }
      }
    }
  }
`
